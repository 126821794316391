@charset "UTF-8";
.van-hairline--bottom[data-v-7cf31cae]::after {
  border: none;
}
.van-tab__pane[data-v-7cf31cae],
.van-tab__pane-wrapper[data-v-7cf31cae] {
  height: calc(100vh - 2.453333rem);
  background: #f9f9f9;
  overflow: scroll;
}
.van-nav-bar[data-v-7cf31cae] .van-nav-bar__text {
  font-size: 0.373333rem;
  color: #333333;
  font-weight: 400;
}
.van-nav-bar[data-v-7cf31cae] .van-icon {
  color: #999;
}
.van-nav-bar[data-v-7cf31cae] .van-nav-bar__left {
  padding: 0 0.213333rem;
}
.van-nav-bar[data-v-7cf31cae] .van-nav-bar__arrow {
  color: #000;
  font-size: 0.533333rem;
  padding: 0;
}
.van-nav-bar[data-v-7cf31cae] .van-nav-bar__title {
  margin: 0 1.546667rem 0 0.933333rem;
  width: 100%;
  max-width: unset;
}
.van-search[data-v-7cf31cae] {
  padding: 0.186667rem 0;
}
.van-search .van-cell[data-v-7cf31cae] {
  font-weight: 500;
  line-height: unset;
}
.van-search .search-icon[data-v-7cf31cae] {
  color: #c5c5c5;
  display: flex;
}
.van-search[data-v-7cf31cae] .van-field__left-icon {
  display: flex;
  align-items: center;
}
.search-page[data-v-7cf31cae] {
  background: #fff;
  height: 100%;
}
.history-list-wrapper[data-v-7cf31cae],
.associate-list-wrapper[data-v-7cf31cae] {
  margin-top: 0.533333rem;
  padding: 0 0.4rem;
}
.history-title[data-v-7cf31cae] {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.373333rem;
  font-weight: bold;
  color: #333333;
  line-height: 0.533333rem;
}
.history-title img[data-v-7cf31cae] {
  width: 0.426667rem;
  height: 0.426667rem;
}
.delete-text[data-v-7cf31cae] {
  font-weight: 400;
  color: var(--themeColor);
  height: 0.426667rem;
}
.delete-text .clear-all[data-v-7cf31cae] {
  margin-right: 0.266667rem;
}
.history-list[data-v-7cf31cae] {
  text-align: left;
}
.history-list .tag-text[data-v-7cf31cae] {
  max-width: 100%;
  word-break: break-all;
  display: block;
}
.tag-more[data-v-7cf31cae] {
  padding: 0.133333rem 0.213333rem;
}
.associate-item[data-v-7cf31cae] {
  display: flex;
  align-items: start;
}
.system-title[data-v-7cf31cae] {
  font-size: 0.373333rem;
  font-weight: bold;
  text-align: left;
  color: #323233;
  line-height: 0.533333rem;
  margin: 0.266667rem 0.4rem 0 0;
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.system-title > span[data-v-7cf31cae] {
  color: rgba(0, 0, 0, 0.2);
  margin-right: 0.106667rem;
}
.associate-content-list[data-v-7cf31cae] {
  font-size: 0.373333rem;
  font-weight: 400;
  text-align: left;
  line-height: 0.533333rem;
  flex: 1;
  width: 0;
}
.associate-title[data-v-7cf31cae],
.associate-title-more[data-v-7cf31cae] {
  color: #333333;
  padding: 0.266667rem 0;
  border-bottom: 0.026667rem solid #ebedf0;
}
.associate-title-more[data-v-7cf31cae] {
  color: #999;
}
.result-wrapper[data-v-7cf31cae] {
  padding: 0.266667rem 0.4rem;
  margin-top: 0.266667rem;
  background: #fff;
  text-align: left;
}
.result-title[data-v-7cf31cae] {
  font-size: 0.373333rem;
  font-weight: bold;
  color: #333333;
  line-height: 0.533333rem;
}
.result-content[data-v-7cf31cae] {
  font-size: 0.32rem;
  font-weight: 400;
  color: #666666;
  line-height: 0.453333rem;
  margin: 0.213333rem 0;
}
.result-desc[data-v-7cf31cae] {
  display: flex;
  justify-content: space-between;
  font-size: 0.32rem;
  font-weight: 400;
  color: #999999;
  line-height: 0.453333rem;
  align-items: flex-start;
}
.result-desc div[data-v-7cf31cae]:first-of-type {
  flex: 1;
  white-space: nowrap;
  /* 防止内容换行 */
  overflow: hidden;
  /* 隐藏超出部分 */
  text-overflow: ellipsis;
  /* 显示省略号 */
}
.result-desc div[data-v-7cf31cae]:last-of-type {
  font-size: 0.266667rem;
  width: 2rem;
  text-align: right;
}
.look-more[data-v-7cf31cae] {
  font-size: 0.32rem;
  font-weight: 400;
  text-align: center;
  color: #333333;
  line-height: 0.426667rem;
  padding: 0.346667rem 0 0.533333rem;
  background: #fff;
  margin-top: 0.026667rem;
}