.empty-wrapper[data-v-57417ae2] {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
img[data-v-57417ae2] {
  width: 4.8rem;
  margin: 0 auto;
}
.empty-desc[data-v-57417ae2] {
  margin-top: 0.426667rem;
  font-size: 0.373333rem;
  font-weight: 400;
  color: #333333;
  line-height: 0.533333rem;
  text-align: center;
}