:root {
  --themeColor: #d43c3f;
  --themeBackgroundColor: #fbecee;
  --redColor: #fe2b20;
  --van-tabs-default-color: var(--themeColor);
  --van-tabs-bottom-bar-color: var(--themeColor);
  --default-color: var(--themeColor);
  --van-nav-bar-text-color: #fff;
  --van-nav-bar-icon-color: #fff;
  --border-color: #dcdfe6;
  --van-search-content-background-color: #fff;
  --van-field-placeholder-text-color: #ccc;
  --body-height: calc(100vh - 2.08rem);
}

.blue-theme {
  --themeColor: #00a4ea;
  --themeBackgroundColor: #f0f9fd;
}

html {
  -webkit-text-size-adjust: none;
}

body {
  color: #333;
  font-size: 0.373333rem;
  padding: 0;
  margin: 0;
}

.van-field__control::placeholder {
  color: var(--van-field-placeholder-text-color);
}

.van-nav-bar__content,
.van-dropdown-menu {
  height: 1.28rem;
}

html,
body {
  margin: 0;
  padding: 0;
  border: 0;
  height: 100%;
}

label {
  font-weight: 700;
}

html {
  height: 100%;
  box-sizing: border-box;
}

#app {
  height: 100%;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

a:focus,
a:active {
  outline: none;
}

a,
a:focus,
a:hover {
  cursor: pointer;
  color: inherit;
  text-decoration: none;
}

div:focus {
  outline: none;
}

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

.app-container {
  min-height: 100%;
  padding: 0 0.64rem;
  margin: 0 auto;
}

p {
  margin: 0;
  -webkit-text-size-adjust: none;
}

.van-checkbox__label {
  margin-left: 0.133333rem !important;
  font-size: 0.32rem !important;
}

.van-cell::after {
  border: none !important;
}

.pick-box {
  padding: 0.266667rem;
  border: 0.026667rem solid #f1f1f1;
  font-size: 0.4rem;
  color: #666666;
  line-height: 0.586667rem;
  width: 100%;
}

.van-field__control {
  color: #333;
}

.van-swipe-cell__right .van-button--danger {
  background-color: var(--redColor);
  border: 0.02667rem solid var(--redColor);
}

.van-popover__content {
  border-radius: 0;
}

.van-tabbar {
  height: 2.08rem;
}

.van-tab--active {
  font-weight: bold;
}

.van-tabbar-item {
  font-size: 0.32rem;
  font-weight: bold;
  color: #999;
}

.van-badge--dot {
  background: var(--redColor);
}

.van-dropdown-menu__bar {
  z-index: 1;
  height: 1.28rem;
  background: #ffffff;
  box-shadow: 0 0.133333rem 0.266667rem 0 rgba(100, 101, 102, 0.08);
}

.van-dropdown-menu__title {
  font-size: 0.346667rem;
  text-align: left;
  line-height: 0.586667rem;
  text-shadow: 0 0.266667rem 0.266667rem 0 rgba(100, 101, 102, 0.08);
}

.van-dropdown-menu__title.menu-selected {
  color: var(--themeColor);
}

.van-dropdown-menu__title.menu-selected::after {
  border-color: transparent transparent currentColor currentColor;
}

.van-dropdown-menu__title.nomalMenu {
  color: #666;
}

.van-dropdown-item__option {
  font-size: 0.373333rem;
  text-align: justify;
  line-height: 0.533333rem;
}

.van-dropdown-item__content {
  border-radius: 0 0 0.533333rem 0.533333rem;
}

.van-calendar__header-title {
  display: none;
}

.van-nav-bar .van-nav-bar__arrow {
  font-size: 0.586667rem;
  color: #333333;
}

.van-nav-bar__title {
  font-size: 0.48rem;
  font-weight: 600;
  color: #333333;
}

.van-grid-item__text {
  margin-top: 0.266667rem;
  font-weight: 400;
  color: #000000;
  text-align: center;
  font-size: 0.293333rem;
  line-height: 0.426667rem;
}

.van-grid-item__content--center {
  justify-content: unset;
  padding: 0.266667rem 0.213333rem;
}

.van-grid-item__content::after,
.van-grid.van-hairline--top::after {
  border: none;
}

.van-list__loading,
.van-list__finished-text,
.van-list__error-text {
  padding-top: 0.533333rem;
}

.c-tag {
  display: inline-block;
  font-size: 0.32rem;
  font-weight: 400;
  text-align: left;
  color: #666666;
  height: 0.746667rem;
  padding: 0.133333rem 0.4rem;
  background: #f7f8fa;
  border-radius: 0.373333rem;
  margin: 0.32rem 0.266667rem 0 0;
  max-width: 100%;
  position: relative;
}

.c-tag .van-icon {
  color: #d8d8d8;
  position: absolute;
  top: 0;
  right: -0.186667rem;
  font-size: 0.373333rem;
}

.c-add-tag {
  position: relative;
  display: inline-block;
  border: 0.013333rem dashed #979797;
  border-radius: 0.053333rem;
  font-size: 0.32rem;
  font-weight: 400;
  text-align: left;
  color: #666666;
  height: 0.746667rem;
  padding: 0.133333rem 0.266667rem;
  margin: 0.32rem 0.266667rem 0 0;
}