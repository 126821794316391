.van-nav-bar[data-v-d9b91160] .van-nav-bar__text {
  color: var(--themeColor);
  font-size: 0.373333rem;
  font-weight: 400;
}
.app-list-wrapper[data-v-d9b91160] {
  height: calc(100vh - 1.28rem);
  overflow-y: scroll;
  padding-bottom: 0.533333rem;
}
.add-icon[data-v-d9b91160],
.cut-icon[data-v-d9b91160] {
  position: absolute;
  top: -0.133333rem;
  right: 0.266667rem;
  font-size: 0.373333rem;
}
.add-icon[data-v-d9b91160] {
  color: var(--themeColor);
}
.cut-icon[data-v-d9b91160] {
  width: 0.32rem;
  height: 0.32rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #d8d8d8;
  border-radius: 50%;
  color: #fff;
  margin: 0.026667rem;
}
.cut-icon span[data-v-d9b91160] {
  display: block;
  height: 0.026667rem;
  width: 0.16rem;
  background: #fff;
}