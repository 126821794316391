@charset "UTF-8";
.swipe-wrapper[data-v-3dd2e005] {
  position: relative;
  height: 5.066667rem;
  border-radius: 0 0 0.213333rem 0.213333rem;
  overflow: hidden;
}
.swipe-wrapper .van-swipe[data-v-3dd2e005] {
  height: 100%;
}
.my-swipe img[data-v-3dd2e005] {
  width: 100%;
  height: 100%;
}
.my-swipe[data-v-3dd2e005] .van-swipe__indicators {
  bottom: 1.066667rem;
  right: 0.96rem;
  left: unset;
  transform: unset;
}
.my-swipe[data-v-3dd2e005] .van-swipe__indicator {
  background: var(--themeBackgroundColor);
}
.my-swipe[data-v-3dd2e005] .van-swipe__indicator--active {
  background-color: var(--themeColor) !important;
  width: 0.266667rem;
  border-radius: 0.16rem;
}
.van-popup--top[data-v-3dd2e005] {
  top: 1.173333rem;
  height: 7.253333rem;
  background: #ffffff;
  border-bottom: 0.013333rem solid #e5e5e5;
  border-radius: 0 0 0.533333rem 0.533333rem;
  padding: 0.4rem;
}
.search-dom-wrapper[data-v-3dd2e005] {
  position: fixed;
  padding: 0.106667rem 0.4rem 0.24rem;
  width: 100%;
  z-index: 9999;
  top: 0;
  height: 1.173333rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.search-dom-wrapper .search-dom-bg[data-v-3dd2e005] {
  position: absolute;
  width: 100%;
  height: 100%;
  content: "";
  display: block;
  background: #f1f1f1;
  top: 0;
  left: 0;
  z-index: -1;
}
.search-dom-wrapper .login-out[data-v-3dd2e005] {
  margin-left: 0.213333rem;
}
.search-dom[data-v-3dd2e005] {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 0.8rem;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 0.4rem;
  font-size: 0.373333rem;
  font-weight: 400;
  color: #666666;
  line-height: 0.533333rem;
}
.search-dom > span[data-v-3dd2e005] {
  margin-right: 0.106667rem;
  margin-top: -0.026667rem;
}
.app-list-wrapper[data-v-3dd2e005] {
  background: #ffffff;
  box-shadow: 0 0 0.186667rem 0 rgba(0, 0, 0, 0.1);
  position: relative;
  border-radius: 0.213333rem;
  margin: 0.266667rem 0.4rem 0.8rem;
  top: -0.96rem;
  padding: 0.266667rem 0;
}
.app-list-wrapper.has-notice[data-v-3dd2e005] {
  margin-bottom: -0.64rem;
}
.van-grid-item[data-v-3dd2e005] {
  border-radius: 0.213333rem;
  overflow: hidden;
}
.function-icon[data-v-3dd2e005] {
  width: 0.8rem;
  height: 0.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background: transparent;
}
.function-icon img[data-v-3dd2e005] {
  text-align: center;
  width: 0.64rem;
}
.notice-wrapper[data-v-3dd2e005] {
  margin: 0.266667rem 0.4rem;
  background: #ffffff;
  border-radius: 0.213333rem;
  box-shadow: 0 0 0.186667rem 0 rgba(0, 0, 0, 0.1);
  padding: 0.213333rem;
  position: absolute;
  bottom: -1.6rem;
  left: -0.4rem;
  width: 100%;
}
.notice-text[data-v-3dd2e005] {
  font-size: 0.32rem;
  font-weight: 400;
  color: #999999;
  margin: 0 0.266667rem;
  flex: 1;
}
.notice-title[data-v-3dd2e005] {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.373333rem;
  font-weight: 600;
  text-align: left;
  color: #000000;
  line-height: 0.533333rem;
}
.notice-title img[data-v-3dd2e005] {
  width: 1.786667rem;
}
.notice-title span[data-v-3dd2e005]:last-child {
  font-size: 0.32rem;
  font-weight: 400;
  color: #999999;
}
.chart-tabs-wrapper[data-v-3dd2e005] {
  position: relative;
}
.chart-tabs-wrapper[data-v-3dd2e005] .van-tabs--line .van-tabs__wrap {
  padding-right: 1.066667rem;
}
.chart-tabs-wrapper[data-v-3dd2e005] .van-tabs__content {
  margin-top: -1.12rem;
}
.chart-tabs-wrapper .change-tabs[data-v-3dd2e005] {
  position: absolute;
  right: 0;
  top: 0;
  width: 1.066667rem;
  height: 1.173333rem;
  background: #fff;
  display: flex;
  justify-content: start;
  align-items: center;
  font-size: 0.533333rem;
}
.chart-tabs-wrapper .change-tabs img[data-v-3dd2e005] {
  height: 0.426667rem;
  padding-left: 0.16rem;
  border-left: 0.053333rem solid #e8e8e8;
  border-bottom-color: transparent;
  border-top-color: transparent;
}
.chart-iframe[data-v-3dd2e005] {
  height: calc(100vh - 1.2rem);
  padding-bottom: 2.133333rem;
}
.all-data-source-wrapper[data-v-3dd2e005] {
  margin-top: 0.4rem;
}
.all-data-source-wrapper .c-tag .van-icon[data-v-3dd2e005] {
  color: #52c41a;
}
.data-source[data-v-3dd2e005] {
  font-size: 0.373333rem;
  line-height: 0.533333rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.data-source-edit[data-v-3dd2e005] {
  font-weight: 400;
  color: var(--themeColor);
}
.data-source-title[data-v-3dd2e005] {
  font-weight: bold;
  color: #333333;
}
.data-source-title span[data-v-3dd2e005] {
  font-size: 0.32rem;
  font-weight: 400;
  color: #999999;
  line-height: 0.48rem;
  margin-left: 0.266667rem;
}

/* 可以设置不同的进入和离开动画 */

/* 设置持续时间和动画函数 */
.slide-fade-enter-active[data-v-3dd2e005] {
  transition: all 0.3s ease;
}
.slide-fade-leave-active[data-v-3dd2e005] {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter[data-v-3dd2e005],
.slide-fade-leave-to[data-v-3dd2e005] {
  transform: translateY(0.133333rem);
  opacity: 0;
}