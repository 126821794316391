.van-grid-item[data-v-08447016] {
  padding-right: 0.213333rem;
}
.van-grid-item[data-v-08447016] .van-grid-item__content--center {
  padding: 0;
}
.van-grid-item[data-v-08447016] .van-grid-item__icon-wrapper {
  width: 100%;
}
.van-grid-item[data-v-08447016] .van-grid-item__text {
  padding-bottom: 0.133333rem;
  font-size: 0.293333rem;
  font-weight: 400;
  color: #000000;
  line-height: 0.453333rem;
}
.app-image[data-v-08447016] {
  margin-top: 0.4rem;
  position: relative;
  display: flex;
  justify-content: center;
}
.app-image img[data-v-08447016] {
  width: 0.64rem;
  height: 0.64rem;
}
.app-item-wrapper[data-v-08447016] {
  background: #ffffff;
  border-radius: 0.213333rem;
  box-shadow: 0 0.026667rem 0.186667rem 0 rgba(0, 0, 0, 0.1);
  padding: 0.32rem 0.32rem 0.133333rem 0.533333rem;
  margin: 0.266667rem 0.266667rem 0;
}
.app-item-title[data-v-08447016] {
  font-size: 0.373333rem;
  color: #333333;
  line-height: 0.533333rem;
  font-weight: bold;
}