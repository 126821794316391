#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
  font-size: 0.373333rem;
}
.van-tabbar {
  z-index: 100;
}
.has-tabbar {
  padding-bottom: 2.08rem;
}
.c-router-view {
  height: 100vh;
  background: #f9f9f9;
}